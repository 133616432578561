import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import '_assets/base.css'
import 'vant/lib/index.css'

document.title = process.env.VUE_APP_TITLE

import {
  Swipe,
  SwipeItem,
  Lazyload,
  Area,
  Toast,
  NavBar,
  Popup,
  Picker,
  Icon,
  Circle,
  Tab,
  Tabs,
  Field,
  Loading,
  Overlay,
  Cell,
  CellGroup,
  Button,
  Dialog,
  List
} from 'vant'
import {getToken} from "_utils/token"
import downloadUrl from "_utils/downloadUrl"

Vue.use(Toast)
  .use(NavBar)
  .use(Popup)
  .use(Picker)
  .use(Icon)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Area)
  .use(Popup)
  .use(Circle)
  .use(Tab)
  .use(Tabs)
  .use(Field)
  .use(Loading)
  .use(Overlay)
  .use(Cell)
  .use(CellGroup)
  .use(Button)
  .use(Dialog)
  .use(List)

Vue.directive('throttle', {
  inserted(el, delay) {
    el.addEventListener('click', () => {
      // 禁用这个指令的DOM结构点击事件
      el.style.pointerEvents = 'none'
      setTimeout(() => {
        // 启动这个指令的DOM结构点击事件
        el.style.pointerEvents = 'auto'
      }, delay.value || 2000) // 默认1秒

    })
  }
})

Vue.config.productionTip = false

window.addEventListener('pageshow', () => {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })

  let lastTouchEnd = 0

  document.addEventListener('touchend', function (event) {
    const now = (new Date()).getTime()
    if (now - lastTouchEnd <= 300) {
      event.preventDefault()
    }
    lastTouchEnd = now

  }, false)

  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
})

Vue.prototype.$getStep = () => JSON.parse(localStorage.getItem('step'))
Vue.prototype.$setStep  = (step) => localStorage.setItem('step', JSON.stringify(step))
Vue.prototype.$getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'))
Vue.prototype.$setUserInfo = (userInfo) => localStorage.setItem('userInfo', JSON.stringify(userInfo))
Vue.prototype.$getHeaderMoney = () => ~~localStorage.getItem('headerMoney')
Vue.prototype.$setHeaderMoney = (money) => localStorage.setItem('headerMoney', money)
Vue.prototype.$getHeaderPercentage = () => ~~localStorage.getItem('percentage')
Vue.prototype.$setHeaderPercentage = (percentage) => localStorage.setItem('percentage', percentage)
Vue.prototype.$isIos = () => {
  const userAgent = navigator.userAgent;
  const isIos =
  !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return isIos
}

Vue.prototype.$toDownload = (type, noCache) => {
  // 特殊渠道跳转不带token
  const token = process.env.VUE_APP_NO_TOKEN_TO_DOWNLOAD ? '' : getToken()
  if (type === 1) {
    location.href = `${downloadUrl}?token=${token}`
  } else {
    localStorage.setItem('toDownload', '1')

    // 跳转时清除缓存
    if (noCache === 1) {
      localStorage.clear()
    }

    location.replace(`${downloadUrl}?token=${token}`)
  }
}
Vue.prototype.$getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const {latitude, longitude} = position.coords
          resolve({latitude, longitude})
        },
        (error) => {
          reject(error.message)
        }
      )
    } else {
      reject("Geolocation is not supported by this browser.")
    }
  })
}

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
