import request from '_utils/request'
import { encode } from 'js-base64'

const save_user_phone_type = (data) => {
  return request({
    url: '/api/user/saveUserPhoneType',
    method: 'POST',
    data
  })
}

const validate_user_name_and_id_card = (data) => {
  // 特殊渠道请求接口不同
  const url = process.env.VUE_APP_SAFE_VALIDATE ? 'api/userInfo/verifyIDCard-sj' : '/api/userInfo/verifyIDCard'

  // 特殊渠道进行处理，姓名和身份证号转base64
  if (process.env.VUE_APP_SAFE_VALIDATE) {
    data.idCard = encode(data.idCard)
    data.userName = encode(data.userName)
  }
  return request({
    url,
    method: 'POST',
    data
  })
}

// 用户退出登录
const user_do_log_out = () => {
  return request({
    url: '/api/user/logout',
    method: 'POST'
  })
}

export {
  save_user_phone_type,
  validate_user_name_and_id_card,
  user_do_log_out
}
