<template>
  <div id="app">
    <router-view/>
    <div
        class="bottom-bar flex-y-center"
        v-if="showBottomBar"
    >
      <van-button
          type="primary"
          size="small"
          @click="rootSecretProtocolPopupIsShow = true"
      >
        隐私协议
      </van-button>
      <van-button
          type="info"
          size="small"
          @click="doLogOut"
      >
        退出登录
      </van-button>
    </div>
    <van-popup
        v-model="rootSecretProtocolPopupIsShow"
        round
    >
      <div class="root-protocol-content-popup-container">
        <iframe
            class="protocol-content"
            src="../protocol/secret-safe-validate.html"
            frameborder="0"
        />
        <div
            class="submit-button"
            @click="rootSecretProtocolPopupIsShow = false"
        >
          好的
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import getSearch from '_utils/getSearch'
import { Dialog } from 'vant'

import { user_do_log_out } from '_network/userInfo'

export default {
  data() {
    return {
      rootSecretProtocolPopupIsShow: false
    }
  },
  computed: {
    showBottomBar() {
      return (
          process.env.VUE_APP_HAS_LOG_OUT_BUTTON
          && this.$route.path !== '/registerLoading'
          && this.$route.path !== '/register'
          && this.$route.path !== '/'
      )
    }
  },
  methods: {
    // 用户退出登录
    async doLogOut() {
      try {
        await Dialog.confirm({
          message: '是否确认退出登录？',
        })
        await user_do_log_out()
        localStorage.clear()
        location.reload()
      } catch (e) {}
    }
  },
  created() {
    if (process.env.VUE_APP_IS_HX_WHOLE_PROCESS_51_REQUEST) {
      axios.get(`${HxRequestApi}/hx/channelUv/jDgROa`)
    }
    if (process.env.VUE_APP_HUAN_TAI) {
      axios.get(`${HxRequestApi}/hx/channelUv/gxiMRW`)
    }
    if (process.env.VUE_APP_CUT_URL_PARAMS) {
      //截取地址配置的参数，域名 + "/hx/channelUv/" + 渠道签名
      const channelSign = getSearch().channelSign
      axios.get(`${HxRequestApi}/hx/channelUv/${channelSign}`)
    }

    const money = this.$getHeaderMoney()
    const percentage = this.$getHeaderPercentage()
    if (!money) {
      this.$setHeaderMoney(5000)
    }

    if (!percentage) {
      this.$setHeaderPercentage(50)
    }

    // 获取缓存时间，如果缓存时间大于12小时则清除token
    const cacheTime = localStorage.getItem('cacheTime')
    if (cacheTime) {
      const currentTime = new Date().valueOf()
      const time = (currentTime - cacheTime) / 3600000
      if (time > 12) {
        localStorage.clear()
        location.reload()
      }
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  .bottom-bar {
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 1.333rem;
    padding: 0 0.533rem;
    background: #FFF;
  }

  .root-protocol-content-popup-container {
    width: 9.067rem;
    padding: 0.427rem;

    .protocol-content {
      overflow-y: auto;
      width: 100%;
      height: 8.7838rem;
    }

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.333rem;
      margin-top: 0.427rem;
      background: #325EF3;
      border-radius: 0.667rem;
      font-size: 0.48rem;
      color: #FFFFFF;
    }
  }
}
</style>
