function getSearch() {
  var args = [];
  var str = location.search.length > 0 ? location.search.substring(1) : "";
  var arrs = str.split("&");
  var item = null,
    m = null,
    n = null;
  for (var i = 0; i < arrs.length; i++) {
    item = arrs[i].split("=");
    m = item[0];
    n = item[1];
    args[m] = n;
  }
  return args;
};

export default getSearch;